export async function uploadDataFile(file, sourceName) {
    const url = "/api/watchdog/upload";

    const formData = new FormData();
    formData.append("file", file);
    formData.append("sourceName", sourceName);

    try {
        const response = await fetch(url, {
            method: "POST",
            body: formData
        });
        if (response.status == 200) {
            return response.json();
        } else {
            throw new Error("Error get datalogger config data");
        }
    } catch (error) {
        throw new Error("Error uploading data file");
    }
}
