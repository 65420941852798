import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import fetchIntercept from "fetch-intercept";

Vue.config.productionTip = false;

fetchIntercept.register({
  request: function (url, config) {
    config = config ?? {};
    config.headers = config.headers ?? {};

    // Set default headers if they do not already exist
    if (!(config.body instanceof FormData)) {
      config.headers["Accept"] = config.headers["Accept"] || "application/json";
      config.headers["Content-Type"] = config.headers["Content-Type"] || "application/json";
    }

    // Ensure credentials are set to "same-origin" if not already defined
    config.credentials = config.credentials || "same-origin";

    return [url, config];
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
