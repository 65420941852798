<template>
  <div v-cloak class="fill-height">
    <Header :menuLeft="false" />

    <v-content class="fill-height">
      <v-container fill-height fluid class="ma-0 pa-0">
        <v-row justify="center">
          <v-col cols="12" sm="12" md="12">
            <h2>Datalogger</h2>
            <v-row justify="center" class="pt-4">
              <v-col cols="12" sm="6" md="4">
                <TitleButtonCard
                  title="Configuración Input"
                  @clickButton="goToDataloggerInput()"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <TitleButtonCard
                  title="Configuración Output"
                  @clickButton="goToDataloggerOutput()"
                />
              </v-col>
            </v-row>
            <v-row justify="center" class="pt-4">
              <v-col cols="12" sm="6" md="4">
                <TitleButtonCard
                  title="Ingesta Quantum"
                  @clickButton="goToQuantumIngest()"
                />
              </v-col>
            </v-row>
            <h2 class="pt-12">Optimizer</h2>
            <v-row justify="center" class="pt-4">
              <v-col cols="12" sm="6" md="4">
                <TitleButtonCard
                  title="Configuración Input"
                  @clickButton="goToOptimizerInput()"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <TitleButtonCard
                  title="Configuración Output"
                  @clickButton="goToOptimizerOutput()"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <!-- <Footer /> -->
  </div>
</template>

<script>
import TitleButtonCard from "@/components/TitleButtonCard.vue";
// import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
  name: "dashboard-item",
  components: {
    TitleButtonCard,
    // Footer,
    Header,
  },
  data: () => ({}),
  methods: {
    goToDataloggerInput: function () {
      this.$router.push("/Datalogger/Config");
    },
    goToDataloggerOutput: function () {
      this.$router.push("/Datalogger/Monitor");
    },
    goToOptimizerInput: function () {
      this.$router.push("/Optimizer/Config");
    },
    goToOptimizerOutput: function () {
      this.$router.push("/Optimizer/Monitor");
    },
    goToQuantumIngest: function () {
      this.$router.push("/Watchdog/Ingest");
    },
  },
};
</script>