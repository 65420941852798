<template>
  <div>
    <MainTitle title="Watchdog - Ingesta de archivos" icon="mdi-database-import" class="pb-0" />

    <v-row>
      <v-col class="pt-0" cols="12" xl="12" lg="12" md="12" sm="12">
        <v-alert class="text-center ml-8 mr-8 mt-2" :value="this.error" dismissible outlined text type="error" color="red"
          @input="closeAlert">
          {{ this.error_msg }}
        </v-alert>
        <v-alert class="text-center ml-8 mr-8 mt-2" :value="this.save_success" dismissible outlined text color="green"
          @input="closeSuccessAlert">
          {{ this.save_success_msg }}
        </v-alert>
      </v-col>
    </v-row>

    <v-container class="pb-8">
      <v-form v-model="valid">
        <v-card>

          <!-- Form Section: Device Name + File Upload -->
          <v-card-text class="pa-4">
            <v-row align="center">
              <!-- Device Name Field -->
              <v-col cols="12" md="12">
                <v-text-field label="Device Name" v-model="sourceName" clearable class="mb-1"
                  :rules="[() => !!sourceName || 'Campo requerido']"></v-text-field>
              </v-col>
            </v-row>

            <!-- File Upload Section -->
            <v-card :class="{ 'drop-active': isDragging, 'drop-inactive': !isDragging }"
              @dragover.prevent="handleDragOver" @dragleave.prevent="handleDragLeave" @drop.prevent="handleDrop">
              <div class="text-center mt-3">
                <v-icon size="48" color="primary">mdi-file-upload</v-icon>
                <p class="text-subtitle-1 font-weight-medium mb-0">Drag and drop your file(s) here</p>
                <p class="text-caption text-muted mb-0">or click below to select files</p>
                <v-file-input v-model="file" class="mr-2 ml-2" label="Select Files" accept="text/plain"
                  :rules="[() => !!file || 'Campo requerido']" @change="handleFileChange">
                </v-file-input>
              </div>
            </v-card>
          </v-card-text>

          <v-divider></v-divider>

          <!-- Upload Button -->
          <v-card-actions class="justify-end">
            <v-btn color="primary" class="white--text" :disabled="!sourceName || !file" @click="uploadData">
              <v-icon left>mdi-upload</v-icon>
              Upload
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import MainTitle from "@/components/MainTitle";

import {
  uploadDataFile,
} from "@/api/watchdog";

export default {
  name: "optimizer-config-item",
  components: {
    MainTitle
  },
  data() {
    return {
      valid: false,
      file: null, // Array to hold the uploaded files
      isDragging: false, // To track the drag-and-drop state
      sourceName: "",
      error: false,
      error_msg: null,
      save_success: null,
      save_success_msg: null
    };
  },
  mounted() {
  },
  methods: {
    handleDragOver() {
      this.isDragging = true; // Highlight the drop area
    },
    handleDragLeave() {
      this.isDragging = false; // Remove highlight when leaving
    },
    handleDrop(event) {
      this.isDragging = false; // Remove highlight
      const droppedFiles = Array.from(event.dataTransfer.files); // Extract files from the drop
      this.file = droppedFiles; // Add to the files array
    },
    handleFileChange(newFiles) {
      this.file = newFiles; // Handle file input changes
    },
    uploadData() {
      uploadDataFile(this.file[0], this.sourceName)
        .then((response) => {
          if (!('error' in response)) {
            this.save_success_msg = "Archivo subido correctamente";
            this.save_success = true;
            this.error = false;
          } else {
            this.error = true;
            this.save_success = false;
            this.error_msg = response.error;
          }
        })
        .catch((err) => {
          this.error = true;
          this.error_msg = err.message;
        });
    },
    closeAlert() {
      this.error = false;
      this.error_msg = null;
    },
    closeSuccessAlert() {
      this.save_success = false;
      this.save_success_msg = null;
    },
  },
};
</script>

<style scoped>
.drop-active {
  border: 2px dashed #1ca75e;
  background-color: #E8F6EE;
  box-shadow: none !important;
}

.drop-inactive {
  border: 2px dashed #dadada;
  box-shadow: none !important;
}
</style>
