import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { lazyLoad } from "@/utils.js";

import Home from "@/views/Home.vue";
import Login from "@/components/Login";
import App from "@/views/App.vue";
import Dashboard from "@/views/Dashboard.vue";
import Administration from "@/views/Administration.vue";
import Manufacture from "@/views/Manufacture.vue";
import Datalogger from "@/views/Datalogger.vue";
import Optimizer from "@/views/Optimizer.vue";
import Watchdog from "@/views/Watchdog/Ingest.vue";

Vue.use(VueRouter);

export const pages = [
  {
    path: "/Datalogger",
    name: "Datalogger",
    icon: "mdi-database-import",
    component: Datalogger,
    children: [
      {
        path: "Config",
        name: "Config",
        component: () => lazyLoad(import("@/views/Datalogger/Config.vue")),
        props: () => ({}),
        meta: {
          title: "Input",
          button: "Input",
          role_required: "Mhaya",
        },
      },
      {
        path: "Monitor",
        name: "Monitor",
        component: () => lazyLoad(import("@/views/Datalogger/Monitor.vue")),
        props: () => ({}),
        meta: {
          title: "Output",
          button: "Output",
          role_required: "Mhaya",
        },
      },
    ],
  },
  {
    path: "/Optimizer",
    name: "Optimizer",
    icon: "mdi-rocket-launch",
    component: Optimizer,
    children: [
      {
        path: "Config",
        name: "Config",
        component: () => lazyLoad(import("@/views/Optimizer/Config.vue")),
        props: () => ({}),
        meta: {
          title: "Input",
          button: "Input",
          role_required: "Mhaya",
        },
      },
      {
        path: "Monitor",
        name: "Monitor",
        component: () => lazyLoad(import("@/views/Optimizer/Monitor.vue")),
        props: () => ({}),
        meta: {
          title: "Output",
          button: "Output",
          role_required: "Mhaya",
        },
      },
    ],
  },

  {
    path: "/Watchdog",
    name: "Watchdog",
    icon: "mdi-rocket-launch",
    component: Watchdog,
    children: [
      {
        path: "Ingest",
        name: "Ingest",
        component: () => lazyLoad(import("@/views/Watchdog/Ingest.vue")),
        props: () => ({}),
        meta: {
          title: "Output",
          button: "Output",
          role_required: "Mhaya",
        },
      },
    ],
  },
];

const routes = [
  {
    path: "/",
    name: "Login",
    component: Home,
    icon: "mdi-home",
    meta: {
      title: "Login",
    },
  },
  {
    path: "/app",
    component: App,
    children: pages,
  },
  {
    path: "/administration",
    name: "Administration",
    component: Administration,
    icon: "mdi-home",
    meta: {
      title: "Home",
      role_required: "Mhaya",
    },
  },
  {
    path: "/manufacture",
    name: "Manufacture",
    component: Manufacture,
    icon: "mdi-home",
    meta: {
      title: "Home",
      role_required: "Operario",
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Dashboard,
    icon: "mdi-home",
    meta: {
      title: "Home",
      role_required: "Mhaya",
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async function (to, _from, next) {
  var logStatus = await Login.check();
  let userRole = store.state.role;

  if (to.name !== "Login" && !logStatus) {
    next({ name: "Login" });
    return;
  }

  if (userRole == "Gon") {
    if (to.name === "Login" && logStatus) {
      next({ name: "Administration" });
      return;
    }
    if (
      !to.name ||
      !to.meta.role_required ||
      to.meta.role_required != userRole
    ) {
      next({ name: "Administration" });
      return;
    }

    next();
  } else if (userRole == "Operario") {
    if (to.name === "Login" && logStatus) {
      next({ name: "Manufacture" });
      return;
    }
    if (
      !to.name ||
      !to.meta.role_required ||
      to.meta.role_required != userRole
    ) {
      next({ name: "Manufacture" });
      return;
    }
    next();
  } else if (userRole == "Mhaya") {
    if (to.name === "Login" && logStatus) {
      next({ name: "Home" });
      return;
    }
    if (
      !to.name ||
      !to.meta.role_required ||
      to.meta.role_required != userRole
    ) {
      next({ name: "Home" });
      return;
    }
    next();
  } else {
    // Entra cuando no esta logueado
    if (!to.name) {
      next({ name: "Login" });
      return;
    }
    next();
  }
});

export default router;
